import { ReactNode } from 'react';

export default function IconText(props: {
  icon: ReactNode;
  text: string;
  size?: 'large' | 'standard' | 'small';
  onClick?: () => void;
  right?: boolean;
}) {
  let textClass = 'transition font-semibold whitespace-nowrap';
  let wrapperClass = 'inline-flex items-center select-none';
  let iconClass = 'transition inline-block size-4';

  const nonSmallText = props.size === 'large' ? 'text-lg' : 'text-base';

  if (props.right) {
    textClass += ' order-first';

    if (props.size === 'small') {
      textClass += ' text-sm mr-1';
    } else {
      textClass += ` mr-2 ${nonSmallText}`;
    }
  } else {
    if (props.size === 'small') {
      textClass += ' text-sm ml-1.5';
    } else {
      textClass += ` ml-2 ${nonSmallText}`;
    }
  }

  if (props.onClick) {
    wrapperClass += ' cursor-pointer group';
    textClass += ' group-hover:text-gray-400 group-hover:translate-x-0.5';
    iconClass += ' group-hover:text-gray-400';

    if (props.right) {
      iconClass += ' group-hover:translate-x-1 group-hover:scale-110';
    } else {
      iconClass += ' group-hover:scale-110';
      textClass += ' group-hover:scale-105';
    }
  }

  return (
    <span className={wrapperClass} onClick={props.onClick}>
      <span className={iconClass}>{props.icon}</span>
      <span className={textClass}>{props.text}</span>
    </span>
  );
}
